import { FaInstagram, FaTelegram, FaWhatsapp } from "react-icons/fa";

export const socialMediaLinks = [
  {
    href: "https://t.me/sarkarijobsamachardotcom",
    icon: FaTelegram,
    bg: "linkedin.500",
    hoverBg: "linkedin.600",
    name: "Telegram",
  },

  {
    href: "https://www.instagram.com/sarkarijobsamacharofficial?igsh=NjVnM3k4cjltamE2",
    icon: FaInstagram,
    bg: "red.500",
    hoverBg: "red.600",
    name: "Instagram",
  },
  {
    href: "https://whatsapp.com/channel/0029VakgPzcB4hdYEeVouX2f", // Replace with your WhatsApp link if needed
    icon: FaWhatsapp,
    bg: "green.500",
    hoverBg: "green.600",
    name: "Whatsapp",
  },
];

export const initialStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

export const GovTypes = ["central", "state", "private", "other"];

export const Sectors = [
  "engineering",
  "medical",
  "railway",
  "ssc",
  "bank",
  "upsc",
  "psc",
  "defence",
  "other",
];
