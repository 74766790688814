export const formatDate = (dateStr, isTime) => {
  // Create a new Date object from the string
  const date = new Date(dateStr);

  // Format the date as DD-MM-YYYY
  const formattedDate = date.toLocaleDateString("en-GB");

  // Format the time in 12-hour format with AM/PM
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = date.toLocaleTimeString("en-US", options);

  if (isTime) return `${formattedDate} || ${formattedTime} `;

  return `${formattedDate}`;
};

export const randomColor = () => {
  return `rgb(${getRandomNumber(100, 255)}, ${getRandomNumber(
    0,
    100
  )}, ${getRandomNumber(0, 255)})`;
};

export const getRandomNumber = (min, max) => {
  // Ensure min and max are integers and min is less than max
  min = Math.ceil(min);
  max = Math.floor(max);

  // Generate a random number between min and max, inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
